import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, IconButton, Skeleton, Typography } from '@mui/material';
/* import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; */
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router';
import { current_user, g_language } from '../../core/UrlService';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color } from '../../themes/themeTelegram';
interface ChipData {
    key: number;
    label: number;
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

interface State {
    numberformat: string;
}

export default function PayRefresh(props: any) {

    const { t, i18n } = useTranslation()

    React.useEffect(() => {
        props.tg.BackButton.show()
        props.tg.BackButton.onClick(() => {
            navigate("/main")
        })
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])

    const [chipData, setChipData] = React.useState<readonly ChipData[]>([
        { key: 0, label: 3 },
        { key: 1, label: 15 },
        { key: 2, label: 30 },
        { key: 3, label: 45 },

    ]);
    const navigate = useNavigate();

    const [description, setDescription] = React.useState(t("pay_refresh.title_4"));
    const [sumToPay, setSumToPay] = React.useState();
    const [values, setValues] = React.useState<State>({
        numberformat: "90"
    });


    const [chips, setChips] = React.useState([]);

    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key == chipToDelete.key));
    };

    const onChipClick = (key: any) => {
        setSumToPay(key)

        setValues({
            ...values,
            "numberformat": key
        });
        /*  if (conn.length > 0) */
        setDescription("for " + (key / (3)) + " days at the current rate")
    }
    var onBackClick = () => {
        navigate("/")
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({

            "numberformat": event.target.value
        });

        if (event.target.value != null) {

            var a = event.target.value.toString().replace(' WP', '');
            //setSumToPay(event.target.value as any)
            /*          if (conn.length > 0) */
            setDescription("for " + Math.round(((Number(a)) / (3))) + " days at the current rate")

            if (Number(a) < 3 * 3) {
                setDescription("min summ" + (3 * 3) + " WP")
            }
            else {
                if (Number(a) > 100000) {
                    setDescription("max summ" + 100000 + " WP")
                }
                else {
                    if (a == "")
                        setDescription("min summ " + (3 * 3) + " WP")
                }
            }
        }
    };


    const setEnabled = () => {

        var res = false;

        if (values.numberformat != null) {
            var a = values.numberformat.toString().replace(' WP', '');
            res = a == "0" || a == "" || Number(a) < 3 * 3 || Number(a) > 3 * 45;
        }


        return res

    }


    var onPayClick = () => {
         
        var sum = values.numberformat
        var a = values.numberformat.toString().replace(' WP', '');

        
        window.open("https://pay.whyrl.ru/pay?user_id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user        ) + "&amount=" + a + "&key=5&status=1", "_blank")
        if (props.tg != null)
            props.tg.close()
    }



    return (
        <Box>

            <Box mt={1} mb={2} sx={{
                display: 'flex',
                color: props.tg.themeParams.text_color ?? '#ffffff',
                flexDirection: 'row',
                alignItems: 'center',


            }}>
           {/*      <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }}  onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton> */}
                <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>{t("pay_refresh.top_up")}</Typography>
            </Box>


            <Box>
                <Typography color={text_color}  sx={{ mb: 2 }}>{t("pay_refresh.title_1")}</Typography>
            </Box>

            <Paper
                sx={{
                    background:  bg_color,
                    display: 'flex',
                    boxShadow: 'none',
                    flexDirection: 'column',
                    /*   justifyContent: 'center',
                      flexWrap: 'wrap', */
                    borderRadius: '12px',
                    listStyle: 'none',
                    p: 2,
                    m: 0,

                }}
                component="ul"
            >


                 <Typography variant="caption" sx={{ color: '#BBBBBB', fontSize: ' 0.675rem', mb: 1 }} >
                 {t("pay_refresh.title_2")}
                </Typography>


                <Grid container spacing={2} mb={1}>

                    <Grid item md={3} xs={6} >


                        <div style={{ display: 'flex' }} className="numeric_custom_div">
                            <NumericFormat
                                onChange={handleChange}
                                value={values.numberformat}

                                className="numeric_custom"
                                style={{ width: '100%', display: 'flex', color: text_color }}

                                suffix=" WP"
                            />
                        </div>
                    </Grid>
                    <Grid item md={2} xs={6}  >
                        <Button   variant="contained" aria-label="add to shopping cart" disabled={setEnabled()} sx={{  borderRadius: 0,  p: 1, width: '100%' }}
                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onPayClick}>
                            {t("pay_refresh.title_3")}
                            <CreditCardIcon sx={{ ml: 0.5 }} />
                        </Button >
                    </Grid>
                </Grid>
                <Typography variant="caption" style={{ color: '#bbbbbb' }}>{description}</Typography>



                <div style={{ display: 'flex', /* justifyContent: 'center' */ }}>
                    {chipData.map((data) => {
                        return (

                            <ListItem key={data.key}>


                            </ListItem>
                        );
                    })}
                </div>


            </Paper>
        </Box>
    )
};
