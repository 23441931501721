import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Box, Grid, IconButton, LinearProgress, LinearProgressProps, Link, Skeleton, Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from '../Dialogs/DeleteDialog';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import "./style.css"
import HelpIcon from '@mui/icons-material/Help';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import { useTranslation } from 'react-i18next';
import { g_language } from '../../../core/UrlService';
import { secondary_bg_color } from '../../../themes/themeTelegram';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, val: number, t: any }) {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, pb: 2 }}>

            {/* <Grid container spacing={2} mb={1.5}> */}
            {/*  <Grid item xs={11}> */}
            <Box sx={{ width: '100%' }}>
                <LinearProgress className='linear-progress-pc' variant="determinate" style={{ background: 'rgb(239, 239, 239)', color: 'red', display: 'flex', alignItems: 'center' }} value={100 * props.val / props.value} />
            </Box>
            <Box sx={{ width: '100%' }}>

                {
                    props.val == -1
                        ? <Typography style={{ textAlign: 'center' }}>{props.t("loading")}...</Typography>
                        : <Typography style={{ textAlign: 'center' }}>{`${props.val.toFixed(2)} из ${props.value} Гб`}</Typography>
                }
                {/*  </Grid> */}
                {/*  <Grid item xs={1}> */}

            </Box>
            {/*   </Grid> */}

            {/*  </Grid> */}
        </Box>
        /*       <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '90%', mr: 1 }}>
                      <LinearProgress variant="determinate" style={{ background: 'rgb(239, 239, 239)' }}  {...props} />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" />{`${Math.round(
                          props.val,
                      )} из 80`}</Typography>
                  </Box>
              </Box> */


    );
}

export default function PcConnection(props: any) {
    const { t, i18n } = useTranslation()
    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])


    var getTariffPrcie = (conn: any) => {

        var price = 0


        price = conn.TarrifPerDay.Int64 == 0 ? 3 : conn.TarrifPerDay.Int64

        //  props.data == null ? 0 :(props.data as any)?.length * 3}

        return price;
    }

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = React.useState(false);

    const [state, setState] = React.useState<any>({
        value: '',
        copied: false,
    })
    const [progress, setProgress] = React.useState(70);


    var getAvatar = (id: any) => {
        if (id == "Android") {
            // return "🤝"
            return (
                <div style={{ marginRight: 5 }}>
                    <AndroidIcon />
                </div>
            )
        }
        if (id == "IPhone") {
            //return "🌐"
            return (
                <div style={{ marginRight: 5 }}>
                    <AppleIcon /></div>

            )
        }
        if (id == "Windows") {
            return (
                <div style={{ marginRight: 5 }}>
                    <LaptopWindowsIcon /></div>
            )
        }

        if (id == "MacOS") {
            //return "🗓"
            return (
                <div style={{ marginRight: 5 }}>
                    <LaptopWindowsIcon /></div>
            )
        }

        return <div style={{ marginRight: 5 }}><AndroidIcon /></div>
    }

    var onCopyClick = (event: any) => {
        /*     setState({ value: 'dddsd', copied: false })
            setAnchorEl(event.currentTarget); */
        setIsOpen(true)

        function sayHi() {
            setIsOpen(false)
        }

        setTimeout(sayHi, 2000);

    }


    return (

        <div>

            <Grid container spacing={2} mb={1.5}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="start"
                        alignItems="center"


                    >


                        <IconButton

                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none', borderRadius: 0 }}
                            onClick={() => { navigate("/edit", { state: { id: props.item.Id, name: props.item.Name?.String == "" ? "empty" : props.item.Name?.String } }) }}
                        >
                            <EditIcon />
                        </IconButton >


                        {/*   <IconButton
                             
                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                            onClick={() => { props.setLoadingState(); props.onChangeProtocClick(props.item.Id, props.item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId)) }}
                        >
                            <RefreshIcon />
                        </IconButton > */}

                        {(props.data.length > 1) ?
                            <IconButton

                                size="small"

                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                sx={{ textTransform: 'none', borderRadius: 0, }}
                                onClick={() => { props.setLoadingState(); props.onDeleteOpenClick(props.item.Id, props.item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId)) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                            : <div></div>
                        }

                        <Button component="label" variant="contained" startIcon={<SystemUpdateAltIcon />} style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ ml: 1, textTransform: 'none', borderRadius: 0 }}
                            onClick={() => { props.onUpdateLimitClick(props.item.TariffPrice.Int64, props.item.TariffLimit.Int64, props.item.Id) }}
                        >
                            {t("refresh_limit_btn")}
                            {/*      <SystemUpdateAltIcon type="file" /> */}
                        </Button>

                    </Grid>


                </Grid>

            </Grid>

            {props.item.Code != '-3' && props.item.InternalId.String?.toString() != ""
                ?
                <div>

                    {/* <Grid container spacing={2} mb={1.5}>
                                                                            <Grid item xs={5}>
                                                                                <Typography>ID</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={7}>
                                                                                <Typography textAlign={'right'}>{props.item.Id}</Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid container spacing={2} mb={1.5}>
                                                                            <Grid item xs={5}>
                                                                                <Typography>Дата создания</Typography>
                                                                            </Grid>


                                                                            <Grid item xs={7}>

                                                                                <Typography textAlign={'right'}>{formatDate} UTC</Typography>
                                                                            </Grid>
                                                                        </Grid> */}


                    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("tariff")}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography textAlign={'right'}>{props.item.TariffPrice.Int64} WP/{t("month")}</Typography>

                        </Grid>
                    </Grid>


                    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("region")}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            {
                                props.item.Flag?.String == ""
                                    ? <Typography textAlign={'right'}> Регион вычислен автоматически</Typography>
                                    : <Typography textAlign={'right'}><i className={"em em-flag-" + props.item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {props.item?.City?.String}</Typography>
                            }

                        </Grid>
                    </Grid>



                    {/*        <Grid container spacing={2} mb={1.5}>
                                                                        <Grid item xs={5}>
                                                                            <Typography>{t("region")}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={7}>
                                                                            <Typography textAlign={'right'}><i className={"em em-flag-" + props.item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {props.item.City?.String}</Typography>
                                                                        </Grid>
                                                                    </Grid> */}

                    {/*    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("server")}</Typography>
                        </Grid>
                        <Grid item xs={7} >
                            <Typography textAlign={'right'}>{props.item.Domain}</Typography>
                        </Grid>
                    </Grid> */}

                    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("status")}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography textAlign={'right'}>{props.item.IsEnabled ? "🟢 OK" : "🔴 " + t("disable")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("device")}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <div style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "flex-end",
                                flexDirection: "row"
                            }}>
                                {/*   {getAvatar(props.item.Device.String)} */}
                                {
                                    props.item.Device.String != null
                                        ? <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }} textAlign={'right'}>{props.item.Device.String}</Typography>
                                        : <Typography textAlign={'right'}>Not selected</Typography>
                                }
                                {/*   <HelpIcon style={{ height: 10 }} /> */}
                            </div>

                        </Grid>
                    </Grid>

                    <Grid item xs={12}>

                        <Grid item xs={5}>

                            <div>
                                <Typography>{t("use_traffic")}</Typography>
                                <Typography variant="caption">{t("use_traffic_last_days")}</Typography>
                            </div>


                        </Grid>
                        <Grid item xs={7}>
                            <LinearProgressWithLabel value={props.item.TariffLimit.Int64} val={props.limit} t={t} />
                        </Grid>


                    </Grid>
                    {/*   <Grid item xs={7}>
                                                                        <Typography textAlign={'right'}>{props.item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
                                                                    </Grid> */}


                    <Box
                        display="flex"
                        flexDirection={"column"}
                        /*  justifyContent="center"
                         alignItems="center" */
                        sx={{
                            borderRadius: '12px'
                        }}

                    >

                        <Box sx={{ mb: 1, textAlign: 'center' }}>
                            <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5', textAlign: 'center', wordWrap: 'break-word' }}>
                                {t("conn_key")}
                            </Typography>
                        </Box>
                        <CopyToClipboard text={props.item.Code}
                            onCopy={() => setState({ copied: true })}>

                            <Box /*  onClick={() => props.test(props.item.Id)} */ bgcolor={props.tg != undefined && props.tg.colorScheme != 'light' ? props.tg.themeParams.bg_color : '#EFEFEF'} style={{
                                backgroundColor: secondary_bg_color, borderRadius: '12px', display: 'flex',
                                justifyContent: 'space-between', alignItems: 'center'
                            }} p={2} className={props.tg != undefined && props.tg.colorScheme != 'light' ? '' : '_link_box'}>


                                <Grid container onClick={onCopyClick} style={{
                                    background: secondary_bg_color
                                }} >
                                    <Grid item xs={10} style={{
                                        background: secondary_bg_color
                                    }}>

                                        {/* <Typography onClick={()=>test(props.item.Code)}>fdfd</Typography> */}


                                        <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5', textAlign: 'left', wordWrap: 'break-word' }}>
                                            {props.item.Code}
                                        </Typography>



                                    </Grid>
                                    <Grid item xs={2} textAlign={"right"} style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }}>
                                        <OpenInNewIcon></OpenInNewIcon>
                                    </Grid>
                                </Grid>
                                {/* 
            
 */}
                            </Box>
                        </CopyToClipboard>
                        {
                            !isOpen
                                ? <div></div>
                                : <Typography sx={{ textAlign: 'center', color: 'lightgreen' /*'dark' ? 'rgb(255 255 255 / 8%)' : 'white'  */ }}>{t("copy")}</Typography>
                        }

                        {/*  <div>{props.item.Code} </div> */}
                        {/*  <Typography >{props.item.Code}</Typography> */}
                    </Box>

                    <Box mt={2}>
                        <Button className='pay_button_instruction' variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ borderRadius: 0, p: 1, width: '100%' }}
                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={() => props.onInstructionClick(props.item.Instruction.String)}>
                            {t("instruction")}
                            {/*  <CreditCardIcon sx={{ ml: 0.5 }} /> */}
                        </Button >
                    </Box>

                </div>
                : <Typography>{t("unrichable")}</Typography>
            }
        </div>

    );
}