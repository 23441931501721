import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AppBar, Avatar, IconButton, Toolbar, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import MenuIcon from '@mui/icons-material/Menu';

import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ShareIcon from '@mui/icons-material/Share';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { useTranslation } from 'react-i18next';
import { g_language } from '../core/UrlService';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { bg_color, text_color } from '../themes/themeTelegram';
import WifiIcon from '@mui/icons-material/Wifi';
type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function NavList(props: any) {
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const { t, i18n } = useTranslation()
    React.useEffect(() => {
        props.tg.BackButton.hide()
        props.tg.MainButton.hide()
       
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])


    const onHistoryClick = (event: React.SyntheticEvent | Event, reason?: string) => {
        navigate(`/history`);
    };

    const onFriendClick = () => {
        navigate(`/friends`);
    }

    const onSpeedTestClick = () => {
        window.open("https://speedtest.whypn.com/", "_blank")
    }




    const onFeedbackClick = () => {
        navigate(`/feedback`);
    }
    const onLogoClick = () => {
        navigate(`/`);
    }

    const onTelegramTestClick = () => {
        navigate(`/test`);
    }

    const onSupportClick = () => {
        navigate(`/support`);
    }
    const onUserInfoClick = () => {
        navigate(`/user_info`);
    }

    const onRoutersClick = () => {
        navigate(`/whypn_routers`);
    }

    const onShareClick = (event: React.SyntheticEvent | Event, reason?: string) => {

        /*   props.tg.requestContact((a:any, b: any) =>{
               
  
              console.log(a)
              console.log(b)
  
          }) */
        navigate(`/user_info`);
    };

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : "100%" }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box p={2}  /* onClick={onUserInfoClick} */>
                <Typography style={{ color: text_color }}>{t("navbar.hello")}, {props.tg.initDataUnsafe.user != undefined ? "@" + props.tg.initDataUnsafe.user.username : "no auth"}</Typography>
            </Box>
            {/* <Divider /> */}
            <List style={{ background: bg_color, borderRadius: '12px' }}>
                {/*           <ListItem key={1} disablePadding>
                    <ListItemButton onClick={onNewClick}>
                        <ListItemIcon style={{ color: text_color}}>
                            <PaymentIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Новое подключение"} />
                    </ListItemButton>
                </ListItem> */}

                <ListItem key={1} disablePadding>
                    <ListItemButton onClick={onHistoryClick}>
                        <ListItemIcon style={{ color: text_color }}>
                            <ReceiptLongIcon />
                        </ListItemIcon>
                        <ListItemText style={{ color: text_color }} primary={t("navbar.history")} />
                    </ListItemButton>
                </ListItem>

                {/*  <ListItem key={1} disablePadding>
                    <ListItemButton onClick={onFriendClick}>
                        <ListItemIcon style={{ color: text_color}}>
                            <PersonAddIcon />
                        </ListItemIcon>
                        <ListItemText style={{ color: text_color}} primary={t("navbar.invite")} />
                    </ListItemButton>
                </ListItem> */}

                {/*        <ListItem key={1} disablePadding>
                    <ListItemButton onClick={onSupportClick}>
                        <ListItemIcon style={{ color: text_color}}>
                            <ThumbUpIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("navbar.support")}/>
                    </ListItemButton>
                </ListItem> */}

                <ListItem key={1} disablePadding>
                    <ListItemButton onClick={onShareClick}>
                        <ListItemIcon style={{ color: text_color }}>
                            <ShareIcon />
                        </ListItemIcon>
                        <ListItemText style={{ color: text_color }} primary={t("navbar.bind")} />
                    </ListItemButton>
                </ListItem>


                <ListItem key={1} disablePadding>
                    <ListItemButton onClick={onFeedbackClick}>
                        <ListItemIcon style={{ color: text_color }}>
                            <FeedbackIcon />
                        </ListItemIcon>
                        <ListItemText style={{ color: text_color }} primary={t("navbar.feedback")} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={1} disablePadding>

                    <ListItemButton onClick={onSpeedTestClick}>
                        <ListItemIcon style={{ color: text_color }}>
                            <RocketLaunchIcon />
                        </ListItemIcon>
                        <ListItemText style={{ color: text_color }} primary={"SpeedTest"} />
                    </ListItemButton>
                </ListItem>


                <ListItem key={1} disablePadding>

                    <ListItemButton onClick={onRoutersClick}>
                        <ListItemIcon style={{ color: text_color }}>
                            <WifiIcon />
                        </ListItemIcon>
                        <ListItemText style={{ color: text_color }} primary={"Роутеры с WhyPN 🆕"} />
                    </ListItemButton>
                </ListItem>


                {/*   <ListItem key={1} disablePadding>
                    <ListItemButton onClick={onFeedbackClick}>
                        <ListItemIcon style={{ color: text_color}}>
                            <AutoStoriesIcon />
                        </ListItemIcon>
                        <ListItemText style={{ color: text_color}} primary={"Обучение"} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={1} disablePadding>
                    <ListItemButton onClick={onFeedbackClick}>
                        <ListItemIcon style={{ color: text_color}}>
                            <AnnouncementIcon />
                        </ListItemIcon>
                        <ListItemText style={{ color: text_color}} primary={"Политика конфиденциальности и правила пользования"} />
                    </ListItemButton>
                </ListItem> */}

                {/*  <ListItem key={1} disablePadding>

                    <ListItemButton onClick={onFeedbackClick}>
                        <FeedbackIcon style={{ color: text_color}}>
                                  <ThumbUpIcon />
                        </FeedbackIcon>
                        <ListItemText primary={"Оставить отзыв"} />
                    </ListItemButton>
                </ListItem> */}


                {/*  <ListItem key={1} disablePadding>

                    <ListItemButton onClick={onSpeedTestClick}>
                        <ListItemIcon style={{ color: text_color}}>
                         
                        </ListItemIcon>
                        <ListItemText primary={"SpeetTest"} />
                    </ListItemButton>
                </ListItem>
 */}







                {/*    <ListItem key={1} disablePadding>

                    <ListItemButton onClick={onTelegramTestClick}>
                        <ListItemIcon style={{ color: text_color}}>
                        
                        </ListItemIcon>
                        <ListItemText primary={"Wallet Pay β"} />
                    </ListItemButton>
                </ListItem> */}
                {/*   {['История', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))} */}
            </List>

        </Box>
    );

    return (
        <div>


            {list('right')}

            {(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.username : "no auth") == null
                ? <div>test</div>
                : <React.Fragment key={'right'}>

                    <Drawer

                        anchor={'right'}
                        open={state['right']}
                        onClose={toggleDrawer('right', false)}                       >
                        {list('right')}
                    </Drawer>
                </React.Fragment>
            }
        </div>
    );
}
