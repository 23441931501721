import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { bg_color, button_color, text_color } from '../themes/themeTelegram';
import ProductHero from './ProductHero';
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax'
import logo from "../assets/mango_2.jpg"
import mango_3 from "../assets/mango_3.png"
import mango_1 from "../assets/mango_1.png"
import SimpleImageSlider from "react-simple-image-slider";

import komplect_postavki from "../assets/komplect_postavki.png"
import sistemny from "../assets/sistemny.png"
import dopolnitelno from "../assets/dopolnitelno.png"
import preimuschestva from "../assets/preimuschestva.png"
import faq from "../assets/faq_1.png"

import { useRef } from 'react';
import { red } from '@mui/material/colors';
import styled, { createGlobalStyle } from 'styled-components'
import { useNavigate } from 'react-router-dom';
import { Height } from '@mui/icons-material';
import Questions from './Questions';
import RunningStringBox from './RunningStringBox';



// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const GlobalStyles: any = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';

    font-weight: bold;
    font-style: normal
  }
  body, input, select, textarea {
    font-family: 'ms_sans_serif';
  }

`

const Page = styled.div`
 
  display: flex;
  justify-content: center;
  align-items: center;

  & > h2 {
    font-size: 10vw;
  }
`
export default function RoutersPage(props: any) {
    const navigate = useNavigate()

    const parallax = useRef<IParallax>(null!)

    var onItemClick = (link: any) => {
        parallax.current.scrollTo(link)
    }
    var onOrderClick = () => {
        navigate("/router_order")
    }


    React.useEffect(() => {


        props.tg.MainButton.setText("Купить с бесплатной доставкой") //  метод для задания текста
        props.tg.MainButton.show() // показать кнопку 
        props.tg.MainButton.color = '#DAFE5A'
        props.tg.MainButton.textColor = '#313131'
        props.tg.MainButton.onClick(() => {
            navigate("/router_order")
        })


        props.tg.BackButton.show()
        props.tg.BackButton.onClick(() => {
            navigate("/nav_list")
        })
        /*       if (props.tg.initDataUnsafe.user.language_code == "en"
              ) {
                  onStartClick()
              } */


    }, [])

    return (


        /*         <div style={{ width: '100%', height: '100%', background: '#253237' }}>
         */

        <Box style={{ /* background: '#ffdf07',  */position: 'absolute', left: 0, right: 0, top: 0, minHeight: '100vh' }}>

            {/*   <RunningStringBox text={""}></RunningStringBox> */}
            <Parallax ref={parallax} pages={6.5}>

                <ParallaxLayer
                    style={{
                        /*  backgroundImage: url('stars', true), */
                        backgroundSize: 'cover',
                        background: 'white',
                        height: '100% '

                    }}
                    offset={0}
                    speed={0}

                >
                    <ProductHero onItemClick={onItemClick}></ProductHero>
                    <Container maxWidth="lg" sx={{ mt: 5 }}>

                        {/* <Box sx={{ mb: 5 }}>
                            <Typography color={"#313131"} align='center' variant='h5'> Беспроводной мини-маршрутизатор для путешествий: всего 39г </Typography>
                            <Typography color={"#313131"} align='center' variant='subtitle1'>Беспроводной мини-маршрутизатор для путешествий: всего 39г </Typography>
                        </Box>

                        <Box>
                            <Typography color={"#313131"} align='center' variant='h5'> Скорость Wi-Fi 300 Мбит/с</Typography>
                            <Typography color={"#313131"} align='center' variant='subtitle1'>Беспроводной мини-маршрутизатор для путешествий: всего 39г </Typography>
                           
                        </Box> */}
                    </Container>


                    {/* <img style={{borderRadius:'25px'}}  src={logo} width={"100%"} height={"50%"} /> */}


                </ParallaxLayer>




                <ParallaxLayer

                    className='propro'
                    offset={1}
                    speed={0.1}
                    factor={1.5}
                    /*   onClick={() => parallax.current.scrollTo(2)} */
                    style={{
                        background: '#ffdf07',
                        borderRadius: '25px',
                        /*  background: 'red', */

                        /*    backgroundSize: '100%',
                           backgroundPosition: 'center', */

                        /*    height: '100%', */


                    }}>

                    <Box >
                        {/*   <Container maxWidth="lg" style={{height: '120vh'}}> */}
                        <Box >

                            <Container maxWidth="lg" sx={{ pt: 5 }}>
                                <Typography color={"#313131"} fontWeight={'bolder'} sx={{ pb: 2 }} variant='h6'>Что в комплекте?</Typography>
                                <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                    1. Роутер GL.iNet со встроенным WhyPN
                                </Typography>
                                <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                    2. USB-кабель для питания 5 V
                                </Typography>
                                <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                    3. Интернет-кабель Ethernet

                                </Typography>
                                <Box sx={{ mt: 2 }}>

                                </Box>
                                <img style={{ borderRadius: '25px' }} src={komplect_postavki} width={"100%"} />

                                {/*   <Button onClick={onOrderClick} variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, borderRadius: 0, p: 1, width: '100%' }}
                                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', background: 'white', color: '#313131' }} >
                                    Оформить предзаказ
                                </Button > */}




                            </Container>


                        </Box>


                        {/*  </Container> */}
                    </Box>

                </ParallaxLayer>

                <ParallaxLayer

                    className='propro'
                    offset={2}
                    speed={-0.1}
                    factor={1.5}
                    /*   onClick={() => parallax.current.scrollTo(2)} */
                    style={{
                        background: 'black',
                        borderRadius: '25px',
                        /*    backgroundSize: '100%',
                           backgroundPosition: 'center', */

                        /*    height: '100%', */


                    }}>

                    <Container maxWidth="lg" sx={{ mt: 5 }}>



                        <Box>
                            <Typography fontWeight={'bolder'} color={"white"} sx={{ pb: 2 }} variant='h6'>
                                Помимо устройства, вы получаете:
                            </Typography>
                            <Typography color={"white"} sx={{ pb: 2 }} variant='body1'>
                                1. Бесплатный доступ к WhyPN до 2025 года
                            </Typography>
                            <Typography color={"white"} sx={{ pb: 2 }} variant='body1'>
                                2. Полное сопровождение процесса первичной настройки
                            </Typography>
                            <Typography color={"white"} sx={{ pb: 2 }} variant='body1'>
                                3. Круглосуточная поддержка
                            </Typography>
                            <Typography color={"white"} sx={{ pb: 2 }} variant='body1'>
                                4. Гарантия работоспособности. При возникновении проблем при настройке — бесплатная замена на новое устройство
                            </Typography>
                            <Typography color={"white"} sx={{ pb: 2 }} variant='body1'>
                                5. <b>5000 WP на баланс WhyPN</b> сразу после покупки для получения бесплатного доступа ко всем другим функциям сервиса
                            </Typography>
                        </Box>
                        <img style={{ borderRadius: '25px' }} src={dopolnitelno} width={"100%"} />
                        {/*   <Button onClick={onOrderClick} variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, mb: 2, åborderRadius: 0, p: 1, width: '100%' }}
                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', background: 'white', color: '#313131' }} >
                            Оформить предзаказ
                        </Button > */}

                    </Container>


                </ParallaxLayer>

                <ParallaxLayer

                    className='propro'
                    offset={3.2}
                    speed={0.1}
                    factor={2}
                    /*   onClick={() => parallax.current.scrollTo(2)} */
                    style={{
                        background: 'white',
                        borderRadius: '25px',
                        /*    backgroundSize: '100%',
                           backgroundPosition: 'center', */

                        height: 'auto',


                    }}>


                    <Container maxWidth="lg" sx={{ pt: 5 }} >



                        <Box>
                            <Typography color={"#313131"} fontWeight={'bolder'} sx={{ pb: 2 }} variant='h6'>
                                Преимущества:
                            </Typography>
                            <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                1. Подключение более 10 устройств одновременно
                            </Typography>
                            <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                2. Подключение любых устройств, на которых есть Wi-Fi: ТВ, приставки, VR-гарнитуры, любые смартфоны и гаджеты умного дома
                            </Typography>
                            <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                3. Не требуется установка дополнительных приложений

                            </Typography>
                            <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                4. Не требуется прошивать уже имеющиеся устройства

                            </Typography>
                            <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                5. Компактный размер и вес меньше 40 грамм позволяет использовать в отелях, кафе и ресторанах
                            </Typography>
                        </Box>
                        <img style={{ borderRadius: '25px' }} src={preimuschestva} width={"100%"} />
                        {/* <Button onClick={onOrderClick} variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, mb: 2, åborderRadius: 0, p: 1, width: '100%' }}
                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', background: '#ffdf07', color: '#313131' }} >
                            Оформить предзаказ
                        </Button > */}


                    </Container>


                </ParallaxLayer>



                <ParallaxLayer

                    className='propro'
                    offset={4}
                    speed={0.5}
                    factor={1.5}
                    /*   onClick={() => parallax.current.scrollTo(2)} */
                    style={{
                        background: '#ffdf07',
                        borderRadius: '25px',
                        /*    backgroundSize: '100%',
                           backgroundPosition: 'center', */

                        /*    height: '100%', */


                    }}>


                    <Container maxWidth="lg" sx={{ pt: 5 }} >



                        <Box>
                            <Typography color={"#313131"} sx={{ pb: 2 }} fontWeight={'bolder'} variant='h6'>
                                Системные требования:

                            </Typography>
                            <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                1. Стабильное подключение к Интернету со скоростью не менее 100 мбит/с

                            </Typography>
                            <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                2. Отсутствие влияния специализированных средств по созданию электромагнитных помех

                            </Typography>
                            <Typography color={"#313131"} sx={{ pb: 2 }} variant='body1'>
                                3. Отсутствие в сети Интернет дополнительных средств фильтрации и блокировок, не предусмотренных провайдером
                            </Typography>

                        </Box>

                        <img style={{ borderRadius: '25px' }} src={sistemny} width={"100%"} />

                        {/*  <Button onClick={onOrderClick} variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, mb: 2, åborderRadius: 0, p: 1, width: '100%' }}
                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', background: 'white', color: '#313131' }} >
                            Оформить предзаказ
                        </Button > */}

                    </Container>


                </ParallaxLayer>



                <ParallaxLayer
                    offset={5}
                    speed={0.1}
                    factor={2.5}
                    style={{

                        backgroundSize: 'cover',
                        background: 'white',
                        borderTopLeftRadius: '25px',
                        borderTopRightRadius: '25px',
                    }}


                >

                    <Container maxWidth="lg" sx={{ pt: 5 }} >
                        <Typography color={"#313131"} sx={{ pb: 2 }} variant='h6' fontWeight={'bolder'} >
                            FAQ

                        </Typography>
                        <Questions></Questions>
                        <Box sx={{ mt: 2 }}>
                            <img style={{ borderRadius: '25px' }} src={faq} width={"100%"} />
                        </Box>


                    </Container>

                </ParallaxLayer>
                <Box style={{ position: 'absolute', bottom: 0, display: 'flex', flexDirection: 'column', background:'white', width:'100%' }}>
                    <Container style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography color={"#313131"} textAlign={'center'} sx={{ pb: 1 }} variant='body1'>
                            Остались вопросы? Задайте их в чат с поддержкой, мы поможем разобраться во всем
                        </Typography>

                        <Typography textAlign={'center'} color={"#313131"} sx={{ pb: 1 }} variant='caption'>
                            WhyPN 2024
                        </Typography>
                    </Container>
                </Box>


                {/*        <ParallaxLayer
                    offset={2}
                    speed={5}
                    style={{
                        backgroundSize: '100%',
                        backgroundPosition: 'center',
                        backgroundImage: mango_3,
                    }}
                /> */}



                {/*    <ParallaxLayer
                    offset={2}
                    speed={-0}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={() => parallax.current.scrollTo(0)}>
                    <img style={{borderRadius:'25px'}}  src={mango_3} style={{ width: '40%' }} />
                </ParallaxLayer> */}

            </Parallax>
        </Box>
        /*  <ProductHero></ProductHero>
<ProductHero></ProductHero> */
        /*  <Parallax pages={2} style={{ top: '0', left: '0', height:'100%' }}>
            <ParallaxLayer offset={0} speed={1} >

            <ProductHero></ProductHero>
               
            </ParallaxLayer>
            <ParallaxLayer offset={1} speed={0.3}>
            <ProductHero></ProductHero>
            </ParallaxLayer>
        </Parallax> */



        /*  <Parallax ref={parallax} pages={2}> */
        /* <div style={{ background: '#ffdf07', position: 'absolute', left: 0, right: 0, top: 0, height: '100vh' }}> */

        /*  <div style={{ width: '100%', height: '100%', background: '#253237' }}>
   
   
   
               <Parallax pages={2} >
                   <ParallaxLayer offset={0} speed={0} style={{
                       backgroundSize: '10%',
                       backgroundPosition: 'center',
                       backgroundColor: 'red'
   
                   }}>
                       <ProductHero></ProductHero>
                   </ParallaxLayer>
   
                   <ParallaxLayer offset={1} >
                       <div>fdfd</div>
                   </ParallaxLayer>
               </Parallax> */

        /*   <motion.div className="progress" style={{ scaleX }} >
                    <Container maxWidth="lg">
                        <Typography align='center' variant='h6'> Беспроводной мини-маршрутизатор для путешествий: всего 39г </Typography>
                        <img style={{borderRadius:'25px'}}  src={logo} width={"100%"} height={"50%"} />

                    </Container>
                </motion.div> */
        /*         <ParallaxLayer offset={0} speed={10}>
                    <Box>
                        <ProductHero></ProductHero>
                    </Box>
                </ParallaxLayer>
                <ParallaxLayer offset={1} speed={10} style={{height:'auto'}}
           > 
                    <Box>
                        <Container maxWidth="lg">
                            <Typography align='center' variant='h6'> Беспроводной мини-маршрутизатор для путешествий: всего 39г </Typography>
                            <img style={{borderRadius:'25px'}}  src={logo} width={"100%"} height={"50%"} />

                        </Container>

                    </Box>
                </ParallaxLayer> */
        /*  </div> */
        /*  </Parallax> */

        /* <Box>
    
                            <Box sx={{
    
                                display: 'flex',
                                color: text_color,
                                flexDirection: 'row',
                                alignItems: 'center',
    
                            }}>
    
    
                            </Box>
    
                          
    
                        </Box> */


        /* 
                <Box>
    
                    <Box sx={{
    
                        display: 'flex',
                        color: text_color,
                        flexDirection: 'row',
                        alignItems: 'center',
    
                    }}>
    
    
                    </Box>
    
                    <ProductHero></ProductHero>
    
                </Box> */

    );
}