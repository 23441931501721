import { Avatar, Box, Container, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material"
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import React from "react";
import { useNavigate } from "react-router-dom";
import Paper from '@mui/material/Paper';
import { useTranslation } from "react-i18next";
import { g_language } from "../core/UrlService";
import { text_color } from "../themes/themeTelegram";
export default function NewUserMain(props: any) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(); 
    React.useEffect(() => {
        props.tg.MainButton.setText(t("main_button.start")) //  метод для задания текста
        props.tg.MainButton.show() // показать кнопку 
       
        props.tg.MainButton.onClick(() => {
            navigate("/rule_page_1")
        })
    }, [])
    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])

    var onHelpClick = () => {
        props.tg.openLink("https://whypn.com/rules.html")
    }

    return (
        <Container maxWidth="lg" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: "space-between" }} sx={{ pt: 2, pb: 2 }}>

            <Box style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <Box style={{ textAlign: 'center', height: "auto", margin: "0 auto", display:"flex", flexDirection:'column', alignItems:'center' }}>
                    {/*  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={require('../assets/shield.svg').default} width={"60%"} height={"60%"} />
                    </Box> */}

                    {/*  {
                         props.tg != undefined && props.tg.colorScheme == 'dark' 
                         ?
                    } */}
                    <Box sx={{ mt: 3 }} style={{ justifyContent: 'center' }}>
                        <img src={require(props.tg != undefined && props.tg.colorScheme == 'dark' ? '../assets/white.svg' : '../assets/black.svg').default} width={"100%"} height={"50%"} />
                    </Box>


                    {/*     <Typography variant="h6" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        Why you need a Private Network?
                    </Typography> */}
                    <Typography variant="body1" sx={{ display: 'flex',color: text_color, width: '100%', mt: 2 }}>
                        {t("new_user_main.title_1")} 
                    </Typography>


                </Box>
            </Box> 
            <Box sx={{ mt: 2, mb: 2 }} >
                {/* <Box sx={{ mt: 2, mb:2, position: 'fixed', bottom: 20, left: 5, right: 5, }} > */}
                {/*    <Typography  variant="caption" sx={{ textAlign: 'center', color: text_color, width: '100%' }} onClick={() => { navigate("/set_up") }}>
                    Нажимая "Начать" вы принимаете Политику конфиденциальности и Правила пользования сервиса WhyPN
                </Typography> */}



                <Typography
                    sx={{ textAlign: 'center', color: text_color }}
                    display="block"
                    color="textSecondary"
                    variant="caption"
                    align="center"
                > {t("new_user_main.title_2")}  <a onClick={onHelpClick} style={{ display: "inline", textDecoration: "underline", fontWeight: 700 }} >{t("new_user_main.title_3")}</a></Typography>
            </Box>
        </Container>
        /*  <div>
         <Grid item xs={12} >
             <Box sx={{ p: 2, mb: 2, background:  bg_color, color: props.tg.themeParams.text_color ?? '#1E88E5', borderRadius: '12px', }} className='test_2' >
 
 
                 <Typography mb={1}>{t("greeting")}, {props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.username : "no auth"} </Typography>
                 <Typography mb={1}>Этот бот подключит тебя к WhyPN</Typography>
 
                
             </Box>
         </Grid>
         <Box sx={{ p: 2, mb: 2, background:  bg_color, borderRadius: '12px' }} >
             <Box>
                 <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }} mb={1}>Выбери вариант подключения</Typography>
 
             </Box>
             {
                 ((conn as any)?.Status == "1" || (conn as any)?.Status == "2") && !chached
                     ? <Box style={{ display: 'flex', justifyContent: 'center' }}>
                         <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                             <CircularProgress />
                             <Typography>
                                 Идет создание подключения...
                             </Typography>
                             <Button   variant="contained" aria-label="add to shopping cart" sx={{ color: 'white', borderRadius: 0, backgroundColor: '#ffc107', p: 1, width: '100%' }}
                                 style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={() => { navigate("/") }}>
                                 Обновить статус
                                 <RefreshIcon sx={{ ml: 0.5 }} />
                             </Button >
                             <Box pt={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                 <Typography>Ссылка для оплаты:</Typography>
                                 <Link>{(conn as any)?.Link.replace(/"/g, '')}</Link>
                             </Box>
                         </Box>
                     </Box>
                     : <Box >
                         {
                             ((conn as any)?.Status == "4") || newStatus == 4
                                 ? <Box pt={5} pb={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                     <Typography> Оплата не прошла &#128551; </Typography>
 
                                     <Button   variant="contained" aria-label="add to shopping cart" sx={{ color: 'white', borderRadius: 0, backgroundColor: '#ffc107', p: 1, width: '100%' }}
                                         style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={() => { repeatCreateConn() }}>
                                         Повторить
                                         <RefreshIcon sx={{ ml: 0.5 }} />
                                     </Button >
 
                                     <Typography pt={2}><b>или</b></Typography>
                                   
                                 </Box>
                                 : <></>
 
                         }
 
                         {
                             ((conn as any)?.Status == "3") || newStatus == 3
                                 ? <Box pt={5} pb={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> Еще немного... </Box>
                                 : <></>
 
                         }
 
                         <div><NewUserPay tg={props.tg}></NewUserPay></div>
                         <div>
                             <Box sx={{ pt: 2 }}><Subscription data={(data as any)?.Connections} tg={props.tg} isLoading={isConnLoading}></Subscription></Box>
                         </div>
 
 
 
 
 
                     </Box>
             }
 
         </Box>
 
     </div> */

    )
}