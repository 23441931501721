import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, IconButton, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { current_user, getUrl } from '../core/UrlService';
import { bg_color, text_color } from '../themes/themeTelegram';

export default function TariffInfo(props: any) {

    const [data, setData] = React.useState([]);
    const navigate = useNavigate();
    const params = useParams();

    var onCreateCodeClick = () => {
        fetch(getUrl("/api/update", props.tg) + "id=" +  (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ) + '&name=' + "test")
        //fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ) + '&name=' + "test")
            .then(res =>
                navigate("history")
            );
    }

    var onBackClick = () => {
        navigate("/")
    }

    return (
        <Box  mb={2}/* style={{ background:  bg_color }} */>
            <Box mb={2} mt={1} sx={{
                /*    background:  bg_color, */
                display: 'flex',
                color: text_color,
                flexDirection: 'row',
                alignItems: 'center',
             
            }}>
                <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton>
{/*                 <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>О тарифе</Typography> */}
            </Box>
            <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color }} sx={{ p: 2 }}>

{/* 
                <Box mb={3}>
                    Текущий общий тариф пользователя WhyPN состоит из суммы тарифов всех подключений пользователя
                </Box>

                <Box mb={3}>
                    <Box><b>Как сменить тариф?</b></Box>
                    Для смены суммы общего тарифа нужно изменить (добавить или удалить) подключения WhyPN. Узнать об актуальных подключениях и их тарифах можно на главной странице Личного кабинета WhyPN в разделе “Подключения”.
                </Box>

                <Box mb={3}>
                    <Box><b> Когда происходит списание согласно тарифу? </b></Box>
                    Ежедневные списания баланса WhyPN производятся согласно общему тарифу производятся в 12:00 МСК. При недостаточном балансе для списания все подключения пользователя блокируются. Для разблокировки достаточно пополнить баланс на любую доступную к пополнению сумму.
                </Box>

                <Box mb={3}>
                    <Box><b>Почему мой тариф больше 3 WP/день?</b> </Box>
                    Общий тариф может быть больше 3 WP/день только в случае, если у вас больше одного подключения WhyPN. Например, если у пользователя 3 подключения (3 QR-кода) по тарифу 3 WP/день, общий тариф пользователя будет составлять 9 WP/день.
                </Box>
                <Box>
                    Остались вопросы? Напишите в поддержку WhyPN. Наши специалисты на связи 24/7
                </Box> */}
            </Box>
            {/* <Box mt={2}>
                <Button style={{
                    width: '100%',
                    background:  bg_color,
                    color: props.tg.themeParams.text_color ?? '#1E88E5',
                    boxShadow: 'none',
                    borderRadius: '12px'
                }} variant="contained" onClick={onCreateCodeClick}>Поддержать WhyPN</Button>
            </Box>
            <Box mt={2}>
                <Typography style={{ width: '100%', fontSize: '12px' }} variant="body2">Средства с пожертвований не учитываются в балансе пользователя, а идут напрямую на поддержку проекта WhyPN. По всем вопросам о донатах обращайтесь в поддержку бота @whypnbot
                </Typography>
            </Box> */}
        </Box >
    );
}