import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({

    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Questions() {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography color="black" >Почему так дорого?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="black" >
                        Мы хотим, чтобы устройства из первой партии попали к тем пользователям, которым действительно они нужны, и для которых вопрос стоимости не является первоочередным или ключевым. Это не массовый продукт, но мы нацелены развивать данное направление и снижать стоимость со временем
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography color="black" >Какая максимальная скорость Интернета от роутера с WhyPN?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="black" >
                        Максимальная скорость зависит от многих параметров, но мы гарантируем скорость передачи по проводу свыше 30 мбит/с. Этого достаточно, чтобы смотреть YouTube в высоком качестве и играть в онлайн игры.

                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography color="black" >Я могу установить WhyPN на свой роутер?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="black" >
                        Нет. Существует много ньюансов при настройке WhyPN на роутерах от провайдеров домашнего интернета. Чтобы избежать проблем, мы запустили данный продукт, где даем гарантии на работоспособность

                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography color="black" >
                        Я могу использотваь роутер без WhyPN?

                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="black" >
                        Да, вы можете использовать этот роутер в качестве обычного роутера, без WhyPN

                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <Typography color="black" >
                    Сколько стоит подписка WhyPN для данного роутера?

                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="black" >
                    До 1 февраля 2025 года - бесплатно. Далее: 500 WP в месяц
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}