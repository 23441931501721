import * as React from 'react';
import ProductHeroLayout from './ProductHeroLayout';
import { bg_color } from '../themes/themeTelegram';
/* import Button from '../components/Button';
import Typography from '../components/Typography'; */
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import SimpleImageSlider from 'react-simple-image-slider';

const backgroundImage =
  'https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400';
  const images = [
    "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
];
export default function ProductHero(props: any) {
  return (
    <ProductHeroLayout
    onItemClick ={props.onItemClick}
      sxBackground={{
      /*   backgroundImage: `url(${backgroundImage})`, */
    /*     backgroundColor: bg_color , */ // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >



      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
       
        alt="increase priority"
      />
{/*       <Typography color="inherit" align="center" variant="h2" marked="center">
        Upgrade your Sundays
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Enjoy secret offers up to -70% off the best luxury hotels every Sunday.
      </Typography> */}
    {/*   <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/premium-themes/onepirate/sign-up/"
        sx={{ minWidth: 200 }}
      >
        Register
      </Button> */}
{/*       <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Discover the experience
      </Typography> */}
    </ProductHeroLayout>
  );
}
