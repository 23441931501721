import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid, IconButton, Link, Popover, Skeleton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { current_user, g_language, getUrl } from '../core/UrlService';
import { useTranslation } from 'react-i18next';
import { bg_color, button_color, secondary_bg_color, text_color } from '../themes/themeTelegram';

export default function Friends(props: any) {

    const { t, i18n } = useTranslation()
    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])


    const [data, setData] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [state, setState] = React.useState<any>({
        value: '',
        copied: false,
    })

    const navigate = useNavigate();

    React.useEffect(() => {
        props.tg.MainButton.hide() // скрыть кнопку

        fetch(getUrl("/api/refferal", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
            //fetch("http://localhost:5010" + "/api/refferal?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ))
            .then(res => res.json())
            .then(
                (result) => {

                    setData(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])

    var onCopyClick = (event: any) => {
        setState({ value: 'dddsd', copied: false })
        setAnchorEl(event.currentTarget);
        setIsOpen(true)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    var onBackClick = () => {
        navigate("/")
    }



    return (
        <Box sx={{pt:2}}>
           {/*  <Box mt={1} mb={2} sx={{
                display: 'flex',
                color: props.tg.themeParams.text_color ?? '#ffffff',
                flexDirection: 'row',
                alignItems: 'center',


            }}>
                <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography color={props.tg.themeParams.text_color ?? '#1E88E5'} variant="h6" gutterBottom sx={{ m: 0 }}>{t("friends.friends_title")}</Typography>
            </Box> */}
            <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}
            >
                <Box mb={3} style={{ color:text_color}}>
                    {t("friends.friends_subtitle")}:
                </Box>
                <Box color={bg_color} style={{
                    backgroundColor: secondary_bg_color, borderRadius: '12px', display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                }} p={2} >


                    <Grid container aria-describedby={id} onClick={onCopyClick} style={{
                        background: secondary_bg_color
                    }} >
                        <Grid item xs={11} style={{
                            background: secondary_bg_color
                        }}>

                            <CopyToClipboard text={"t.me/whypnbot?start=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : -1)}
                                onCopy={() => setState({ copied: true })}>

                                <Link href="#" underline="hover" style={{ color: button_color, textAlign: 'center' }}>
                                    t.me/whypnbot?start={props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : -1}
                                </Link>
                            </CopyToClipboard>

                        </Grid>
                        <Grid item xs={1} textAlign={"right"} style={{ color: button_color}}>
                            <OpenInNewIcon></OpenInNewIcon>
                        </Grid>
                    </Grid>
                    {/* 
                    <Popover
                        id={id}
                        open={open}
                        style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    > */}

                    {/*     </Popover>
 */}
                </Box>
                {
                    !isOpen
                        ? <div></div>
                        : <Typography sx={{ textAlign: 'center', color: 'lightgreen' /*'dark' ? 'rgb(255 255 255 / 8%)' : 'white'  */ }}>{t("copy")}</Typography>
                }

                <Box style={{ color: text_color}} mt={2}>
                    {t("friends.friends_desc_1")}
                </Box>

                {
                    data.length == 0
                        ?
                        <Box mt={2} style={{ color: text_color}}>
                            <Skeleton variant="rectangular" />
                        </Box>
                        : <Box mt={2} style={{ color: text_color}}>
                            {t("friends.friends_clicked_link")}: {data.length > 1 ? (data[1] as any).Count : 0}
                        </Box>
                }

                {
                    data.length == 0
                        ?
                        <Box mt={1} style={{ color: text_color }}>
                            <Skeleton variant="rectangular" />
                        </Box>
                        : <Box mt={1} style={{ color:text_color }}>
                            {t("friends.friends_connected")}: {data.length > 1 ? (data[0] as any).Count : 0}
                        </Box>
                }



            </Box>
        </Box >
    );
}