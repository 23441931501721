import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Skeleton, Typography } from '@mui/material';
import { text_color } from '../../../themes/themeTelegram';
import { useTranslation } from 'react-i18next';
import { g_language } from '../../../core/UrlService';

export default function AlertDialog_1(props: any) {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
}, [])

  const handleClickOpen = () => {
     
    props.handleClickOpen();
   
  };


  const handleApprove = () => {
 
    props.handleApprove()
  };
  const handleClose = () => {
    
    props.handleClose();
  };


  React.useEffect(() => { setOpen(props.dialog_open) }, [props.dialog_open]);
  return (


    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white'


        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ color: text_color}}>
        <Typography variant='h6'>{t("alert_refresh.name")} </Typography>
      </DialogTitle>
      <DialogContent>
        {
          props.isLoading
            ? <Typography variant="h4" sx={{ width: '100%', flexShrink: 0 }} style={{ width: '100%;', color: props.tg.themeParams.text_color ?? 'black' }}><Skeleton></Skeleton></Typography>
            : <DialogContentText id="alert-dialog-description" style={{ color: text_color}}>
              {props.text}
              {/*  Выбранное подключение будет удалено.
          Вместо него будет создано новое подключение протокола Outline */}
            </DialogContentText>
        }
      </DialogContent>

      {
        !props.isOk
          ? <DialogActions>

            <Button onClick={handleClose} disabled={props.isLoading}   style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', background: "transparent", color:text_color}}>  {t("alert_refresh.cancel")}</Button>
            <Button onClick={handleClickOpen} disabled={props.isLoading}>
             {t("alert_refresh.yes")}
            </Button>
          </DialogActions>
          : <DialogActions>

            <Button onClick={handleApprove} disabled={props.isLoading} >  {t("alert_refresh.ok")}</Button>

          </DialogActions>
      }

    </Dialog>

  );
}