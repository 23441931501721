import { Avatar, Box, Button, Card, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import "./styles.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { current_user, getUrl } from '../../core/UrlService';
import { bg_color } from '../../themes/themeTelegram';



export default function SubscriptionDescription(props: any) {
    const navigate = useNavigate();
    const [data, setData] = React.useState([]);
    const [visible, setVisible] = React.useState(false);


    React.useEffect(() => {
        fetch(getUrl("/api/sub", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
        //fetch("http://localhost:5010" + "/api/sub?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {
                    setVisible(result)
                    /* setData(result.Connections); */
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])

    const onMainBlockClick = () => {
        /* navigate("/tariff") */
        window.open("https://t.me/+ooTBYfw-xI05ZjUy", "_blank")
        navigate("/")
    }

    /*     const onFreeClick = () =>{
            fetch("http://localhost:5010" + "/api/sub?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {
                       
                },
                (error) => {
                }
            )
        }
     */

    var onBackClick = () => {
        navigate("/")
    }

    return (
        <Box /* style={{ background:  bg_color }} */>
            <Box mb={2} mt={1} sx={{
                /*    background:  bg_color, */
                display: 'flex',
                color: props.tg.themeParams.text_color ?? '#ffffff',
                flexDirection: 'row',
                alignItems: 'center',

            }}>
                <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{ }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton>
               {/*  <Typography color={props.tg.themeParams.text_color ?? '#1E88E5'} variant="h6" gutterBottom sx={{ m: 0 }}> Попробовать WhyPN бесплатно
                </Typography> */}
            </Box>
            <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none', color: props.tg.themeParams.text_color ?? '#1E88E5' }} sx={{ p: 2 }}>
              {/*   <Box>
                    Подпишитесь на <b>WhyPN</b> Блог и получите бонусное пополнения баланса, которого хватит <b>до трёх дней</b> бесплатного использования <b>WhyPN</b>
                </Box>
                <Box>
                    В <b>WhyPN Блоге</b> мы рассказываем про сложные Интернет-технологии простыми словами, отвечаем на вопросы по подключению, сообщаем о наших обновлениях
                </Box> */}
            </Box>




            <Box mt={2}>
                <Button style={{
                    width: '100%',
                    
                    
                    boxShadow: 'none',
                    borderRadius: '12px'

                }}
                    onClick={onMainBlockClick}
                    variant="contained" /* onClick={onCreateCodeClick} */>{/* Подписаться на WhyPN Блог    */}             </Button>
            </Box>
            <Box mt={2}>
                <Typography style={{ width: '100%', fontSize: '12px', color: props.tg.themeParams.text_color ?? '#1E88E5' }} variant="body2">После подписки вернитесь в Личный кабинет для автоматического начисления бонусов. В случае отписки бонусы могут быть аннулированы. По всем вопросам о пробном периоде WhyPN обращайтесь в поддержку бота @whypnbot

                </Typography>
            </Box>
        </Box >
    );
};
