import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeList from '../components/Code/CodeList';
import { Box, Button, IconButton, Skeleton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import PayTemp from '../components/Temp/PayTemp';
import { connect } from 'http2';
import CreateCode from '../components/Connection/CreateCode';
import { current_user, g_language, getUrl } from '../core/UrlService';
import PayList from '../components/Balance/PayList';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color } from '../themes/themeTelegram';
import { useDispatch } from 'react-redux';
import { incrementByAmount, setUserInfo as te } from '../redux/counterSlice';

export default function CheckCreation(props: any) {



    const location = useLocation();
    const [data, setData] = React.useState(location.state.conn);
    const [userInfo, setUserInfo] = React.useState(location.state.info);
    const [isLoading, setIsLoading] = React.useState(false);

    const [isConnLoading, setIsConnLoading] = React.useState(true);
    const [isUserInfoLoading, setIsUserInfoLoading] = React.useState(true);

    const navigate = useNavigate();
    const params = useParams();
    const { t, i18n } = useTranslation()

    React.useEffect(() => {

        props.tg.BackButton.show()
        props.tg.BackButton.onClick(() => {
            navigate("/main")
        })

        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

        /*   fetch(getUrl("/api/connections", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ))
          //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
              .then(res => res.json())
              .then(
                  (result) => {
                      setIsConnLoading(false)
                      setData(result.Connections);
                  },
                  (error) => {
  
                  }
              ) */

        /*     fetch(getUrl("/api/user_info", props.tg) + "id=" +(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       )))
        //fetch("http://localhost:5010" + "/api/user_info?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       )))
            .then(res => res.json())
            .then(
                (result) => {
                    setIsUserInfoLoading(false)
                    setUserInfo(result);
                },
                (error) => {
                }
            ) */


    }, [])

    var dispatch = useDispatch()
    var onCreateCodeClick = () => {
        fetch(getUrl("/api/create_by_country", props.tg) + "id=" +
            ///* "http://localhost:5010" */  '/api/create_by_country?id='
            + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user)
            + '&name='
            + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : "test")
            + "&flag=" + location.state.id
            + "&device=" + location.state.device_id
            + "&conn_name=" + location.state.name
            + "&protocol=" + location.state.protocol

            + "&from_country=" + location.state.from_country
            + "&tarif=" + location.state.tarif.Id

        )
            .then(res => {
                debugger
                if (res.status != 200) {
                    setIsLoading(false)
                    debugger
                    props.tg.showPopup({
                        title: t("set_up.error_title"),
                        message: t("set_up.error_desc"),
                        buttons: [
                            {
                                id: 1,
                                type: "default",
                                text: t("set_up.error_close_button")
                            }
                        ]
                    }, (button_id: any) => {

                    }
                    )
                }

                else {
                    dispatch(incrementByAmount([]))
                    dispatch(te(null))
                    navigate("/main")
                }
            },
                (error) => {

                    debugger
                    props.tg.showPopup({
                        title: t("set_up.error_title"),
                        message: t("set_up.error_desc"),
                        buttons: [
                            {
                                id: 1,
                                type: "default",
                                text: t("set_up.error_close_button")
                            }
                        ]
                    }, (button_id: any) => {

                    })




                }
            )
            ;
        /*   {location.state.id}
          fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ) + '&name=' + "test")
              .then(res =>
                  navigate("/")
              ); */
    }

    var onPayClick = () => {



        window.open("https://pay.whyrl.ru/pay?user_id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&amount=" + location.state.tarif.Price + "&key=5&status=1", "_blank")
    }


    var onBackClick = () => {
        navigate("/new", { state: { info: userInfo, conn: data } })
    }


    var getTariffPrcie = () => {


        var price = 0
        var conn = (data as any);

        for (var i = 0; i < conn?.length; i++) {
            if (conn[i].Type.String == "" || conn[i].Type.String == "daily") {
                price += conn[i].Type.String == "" ? 3 : conn[i].TariffPrice.Int64
            }
        }

        //price += conn[i].TarrifPerDay.Int64 == 0 ?   conn[i].TariffLimit.Int64 == 0 ? conn[i].TariffPrice.Int64 : 0 :conn[i].TariffPrice.Int64/* conn[i].TarrifPerDay.Int64 */        }
        //  props.data == null ? 0 :(props.data as any)?.length * 3}

        return price;
    }



    return (
        /* <CreateCode tg={props.tg}></CreateCode> */
        <Box>
            {
                false
                    /* isUserInfoLoading */
                    ?
                    <Box>
                        <Box mb={2} mt={1} sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0, width: '100%' }}> <Skeleton /></Typography>
                        </Box>
                        <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                            <Box mb={1} style={{ color: text_color }}>
                                <Skeleton height={40}></Skeleton>
                            </Box>
                            <Box style={{ color: text_color }}>
                                <Skeleton height={40}></Skeleton>
                            </Box>
                        </Box>

                    </Box>
                    :



                    location.state.device == 3 || location.state.device == 4
                        ?

                        (userInfo[0] as any).TotalNow >= 90 ?
                            <Box>
                                <Box mb={2} mt={1} sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    {/*   <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                                        <ArrowBackIcon />
                                    </IconButton> */}
                                    <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>  Getting a connection</Typography>
                                </Box>
                                <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                                    <Box mb={3} style={{ color: text_color }}>
                                        {t("check.title_1")}<b> 90  WP</b>

                                    </Box>
                                    <Box style={{ color: text_color }}>
                                        {t("check.title_2")}: <b> 500   {t("check.title_3")}</b>
                                    </Box>
                                </Box>
                                {/* <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2, mt:2, mb:2 }}>
                            <CreateCode tg={props.tg}></CreateCode>
                        </Box> */}

                                <Box mt={3}>
                                    {
                                        isLoading
                                            ?
                                            <LoadingButton style={{
                                                width: '100%',
                                                background: bg_color,
                                                boxShadow: 'none',
                                                borderRadius: '12px'
                                            }} loading variant="outlined">
                                                {t("check.load")}
                                            </LoadingButton>
                                            : <Button style={{
                                                width: '100%',

                                                boxShadow: 'none',
                                                borderRadius: '12px'
                                            }} variant="contained" onClick={() => { setIsLoading(true); onCreateCodeClick() }}>{t("check.yes")}</Button>
                                    }

                                </Box>

                            </Box >
                            : <Box>
                                <Box mb={2} mt={1} sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    {/*  <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                                        <ArrowBackIcon />
                                    </IconButton> */}
                                    <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}> {t("check.title_4")}</Typography>
                                </Box>
                                <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                                    <Box mb={3} style={{ color: text_color }}>
                                        {t("other.lower_balance")}
                                    </Box>
                                    <Box style={{ color: text_color }}>
                                        {t("other.title_1")}: {location.state.tarif.Price} WP
                                    </Box>
                                </Box>
                                <Box mt={3}>


                                    {/*   <Button style={{
                                        width: '100%',
                                        background:  bg_color,
                                        color: text_color,
                                        boxShadow: 'none',
                                        borderRadius: '12px'
                                    }} variant="contained" onClick={() => { navigate("/") }}>Понятно</Button>


                                    <PayList tg={props.tg} tarif={data}></PayList> */}
                                    <Button variant="contained" aria-label="add to shopping cart" sx={{ borderRadius: 0, p: 1, width: '100%' }}
                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onPayClick}>
                                        Top up
                                        <CreditCardIcon sx={{ ml: 0.5 }} />
                                    </Button >


                                </Box>

                            </Box >




                        : location.state.from_country == 2
                            ?

                            (userInfo[0] as any).TotalNow >= location.state.tarif.Price ?
                                <Box>
                                    <Box mb={2} mt={1} sx={{

                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        {/*  <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                                                <ArrowBackIcon />
                                            </IconButton> */}
                                        <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>  Getting a connection</Typography>
                                    </Box>
                                    <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                                        <Box mb={3} style={{ color: text_color }}>
                                            Total amount for 30 days <b>{location.state.tarif.Price} WP</b>

                                        </Box>
                                        <Box style={{ color: text_color }}>
                                            GBs: <b>{location.state.tarif.Limit}</b>
                                        </Box>
                                    </Box>
                                    {/* <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2, mt:2, mb:2 }}>
                            <CreateCode tg={props.tg}></CreateCode>
                        </Box> */}

                                    <Box mt={3}>
                                        {
                                            isLoading
                                                ?
                                                <LoadingButton style={{
                                                    width: '100%',
                                                    background: bg_color,
                                                    boxShadow: 'none',
                                                    borderRadius: '12px'
                                                }} loading variant="outlined">
                                                    Loading
                                                </LoadingButton>
                                                : <Button style={{
                                                    width: '100%',

                                                    boxShadow: 'none',
                                                    borderRadius: '12px'
                                                }} variant="contained" onClick={() => { setIsLoading(true); onCreateCodeClick() }}>{t("buttons.yes_next")}</Button>
                                        }

                                    </Box>

                                </Box >
                                : <Box>
                                    <Box mb={2} mt={1} sx={{

                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        {/* <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                                            <ArrowBackIcon />
                                        </IconButton> */}
                                        <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}> Getting a connection</Typography>
                                    </Box>
                                    <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                                        <Box mb={3} style={{ color: text_color }}>
                                            {t("other.lower_balance")}
                                        </Box>
                                        <Box style={{ color: text_color }}>
                                            {t("other.title_1")}: {location.state.tarif.Price} WP
                                        </Box>
                                    </Box>
                                    <Box mt={3}>


                                        <Button variant="contained" aria-label="add to shopping cart" sx={{ borderRadius: 0, p: 1, width: '100%' }}
                                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onPayClick}>
                                            Top up
                                            <CreditCardIcon sx={{ ml: 0.5 }} />
                                        </Button >


                                    </Box>

                                    {/*   <Box mt={3}>
                                        <PayList tg={props.tg} tarif={data}></PayList>

                                    </Box> */}
                                </Box >





                            : data.length == 0
                                ? <PayTemp tg={props.tg}></PayTemp>
                                :
                                (userInfo[0] as any).TotalNow >= 3 ?
                                    <Box>
                                        <Box mb={2} mt={1} sx={{

                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            {/*  <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                                                <ArrowBackIcon />
                                            </IconButton> */}
                                            <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}> {t("new_page.getting_connected")}</Typography>
                                        </Box>
                                        <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                                            <Box mb={3} style={{ color: text_color }}>
                                                {t("check.general_tariff")} <b> {t("from")} {getTariffPrcie()} WP/{t("day")} {t("to_")} {getTariffPrcie() + (location.state.from_country == "2" ? 0 : location.state.tariffPrice)}  WP/{t("day")}</b>
                                            </Box>
                                            <Box style={{ color: text_color }}>
                                                {t("check.write_off_for_first_day")}  {location.state.tariffPrice} WP
                                            </Box>
                                        </Box>
                                        {/* <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2, mt:2, mb:2 }}>
                                    <CreateCode tg={props.tg}></CreateCode>
                                </Box> */}

                                        <Box mt={3}>
                                            {
                                                isLoading
                                                    ?
                                                    <LoadingButton style={{
                                                        width: '100%',
                                                        background: bg_color,
                                                        boxShadow: 'none',
                                                        borderRadius: '12px'
                                                    }} loading variant="outlined">
                                                        Loading
                                                    </LoadingButton>
                                                    : <Button style={{
                                                        width: '100%',

                                                        boxShadow: 'none',
                                                        borderRadius: '12px'
                                                    }} variant="contained" onClick={() => { setIsLoading(true); onCreateCodeClick() }}>{t("buttons.yes_next")}</Button>
                                            }

                                        </Box>

                                    </Box >
                                    : <Box>
                                        <Box mb={2} mt={1} sx={{

                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                            <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>Receive an additional QR-code</Typography>
                                        </Box>
                                        <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                                            <Box mb={3} style={{ color: text_color }}>
                                                {t("other.lower_balance")}
                                            </Box>
                                            <Box style={{ color: text_color }}>
                                                {t("other.title_2")}: 3 WP
                                            </Box>
                                        </Box>
                                        <Box mt={3}>


                                            {/*    <Button style={{
                                                width: '100%',
                                                background:  bg_color,
                                                color: text_color,
                                                boxShadow: 'none',
                                                borderRadius: '12px'
                                            }} variant="contained" onClick={() => { navigate("/") }}>Понятно</Button> */}


                                        </Box>

                                    </Box >
            }
        </Box>
    );
}