import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Link, Radio, RadioGroup, TextField, Theme, ThemeProvider, createTheme, outlinedInputClasses, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { current_user, g_language, getUrl } from '../core/UrlService';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color } from '../themes/themeTelegram';


export default function Feedback(props: any) {



    const [data, setData] = React.useState([]);
    const navigate = useNavigate();
    const params = useParams();

    React.useEffect(() => { 
        props.tg.BackButton.show()
        props.tg.BackButton.onClick(() => {
            navigate("/nav_list")
        })

        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])


    var onCreateCodeClick = () => {
        /*    fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ) + '&name=' + "test")
               .then(res =>
                   navigate("history")
               ); */
        window.open('https://donate.qiwi.com/payin/WhyPN', '_blank')
    }

    var onBackClick = () => {
        navigate("/")
    }
    const [value, setValue] = React.useState('');
    const [text, setText] = React.useState("");
    const [desc, seDesc] = React.useState("");
    const [disabled, seDisabled] = React.useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);

    };



    const customTheme = (outerTheme: Theme) =>
        createTheme({
            /*     palette: {
                    mode: outerTheme.palette.mode,
                }, */
            components: {
                MuiTextField: {

                    styleOverrides: {

                        /*       root: {
      
                                  color: props.tg.themeParams.text_color ?? 'red',
                                  '--TextField-brandBorderColor': '#E0E3E7',
                                  '--TextField-brandBorderHoverColor': '#B2BAC2',
                                  '--TextField-brandBorderFocusedColor': '#6F7E8C',
                                  '& label.Mui-focused': {
                                      color: text_color,
                                  },
                              }, */
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
/*                             borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd',
 */                            border: "1px solid",

                            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'

                        },
                        root: {
                            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",

                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",

                                /*     borderColor: '#bdbdbd' */
                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                        },
                    },
                },

            },
        });

    const onFeedbackClick = () => {
        seDisabled(true)
        fetch(getUrl("/api/feedback", props.tg)
            + "userId=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user)
            + "&text=" + "Вам нравится WhyPN:  %23" + value + ", Что нам стоит улучшить: " + text

        )
            .then(res => { return res.json() })
            /*  fetch("http://localhost:5010" + '/api/remove_old?id=' + user_id) */
            .then(res => {
                /*  props.refresh() */
                /*   window.location.reload(); */
                /*  seDesc(res.Desc) */
                navigate("/feedback_result", { state: { desc: res.Status } })
            }, error => {
                /*  window.location.reload(); */
            })
            ;
    }
    const outerTheme = useTheme()


    const { t, i18n } = useTranslation()

    return (
        <Box /* style={{ background:  bg_color }} */>
            <Box mb={2} mt={1} sx={{
                /*    background:  bg_color, */
                display: 'flex',
                color: text_color,
                flexDirection: 'row',
                alignItems: 'center',

            }}>
               {/*  <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} style={{ color: text_color }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton> */}
                <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}> {t("feedback.feedback_title")} </Typography>
            </Box>
            <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color }} sx={{ p: 2 }}>
                <FormControl>
                    <Typography color={text_color}>{t("feedback.feedback_quest_1")}</Typography>
                    <RadioGroup
                        style={{ color: text_color }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        <FormControlLabel style={{ color: text_color }} value="Yes" control={<Radio style={{ color: text_color }} />} label={t("feedback.feedback_quest_1_yes") + " 👍"} />
                        <FormControlLabel style={{ color: text_color }} value="No" control={<Radio style={{ color: text_color }} />} label={t("feedback.feedback_quest_1_no") + " ☹️"} />
                    </RadioGroup>
                </FormControl>
            </Box>

            <Box mt={2} style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color }} sx={{ p: 2 }}>
                <Typography color={{text_color}}>{t("feedback.feedback_quest_2")}</Typography>



                <ThemeProvider theme={customTheme(outerTheme)}>
                    {/*    <TextField

                    
                        multiline
                        rows={4}
                        sx={{ input: { color: props.tg.themeParams.text_color ?? 'red' }, mt: 2 }}
                        focused
                        style={{ borderRadius: '12px', color: props.tg.themeParams.text_color ?? 'red', width: '100%' }}
                        onChange={handleText}
                        value={text}

                    />
 */}

                    <TextField
                        sx={{ input: { color: text_color }, width: '100%', mt: 2 }}
                        InputProps={{ sx: { borderRadius: '12px', color:text_color  }}} value={text}
                        style={{ borderRadius: '12px', color: text_color }}
                        multiline
                        rows={4}
                        id="test" variant="outlined" onChange={handleText} focused />

                </ThemeProvider>

            </Box>
            <Box mt={2}>
                <Typography color={text_color} style={{ width: '100%', fontSize: '12px' }} variant="body2">
                    {t("feedback.feedback_desc")}
                </Typography>
            </Box>


            <Box mt={2}>
                {/*   <Button style={{
                    width: '100%',
                    background:  bg_color,
                    color: text_color,
                    boxShadow: 'none',
                    borderRadius: '12px'
                }} variant="contained" onClick={onCreateCodeClick}>Отправить</Button> */}

                <Button   variant="contained" aria-label="add to shopping cart" sx={{  borderRadius: 0,  p: 1, width: '100%' }}
                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onFeedbackClick} disabled={disabled || value == ""}>
                    {t("feedback.feedback_send_btn")}
                    {/*    <CreditCardIcon sx={{ ml: 0.5 }} /> */}
                </Button >

                {/*     {
                    desc != ""
                        ?
                        <Box sx={{ mt: 1 }}>
                            <Typography textAlign={"center"} color={text_color} style={{ width: '100%' }} variant="h6">{desc}</Typography>
                        </Box>
                        : <></>
                } */}
            </Box>
            {/*       <Box mt={2}>
                <Typography color={text_color}  style={{ width: '100%', fontSize: '12px' }} variant="body2">Средства с пожертвований не учитываются в балансе пользователя, а идут напрямую на поддержку проекта WhyPN. По всем вопросам о донатах обращайтесь в поддержку бота @whypnbot
                </Typography>
            </Box> */}
        </Box >
    );
} 